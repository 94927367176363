// @ts-strict-ignore
import _ from 'lodash';
import { STRING_UOM } from '@/main/app.constants';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import i18next from 'i18next';
import { ENUM_REGEX } from '@/trendData/trendData.constants';
import { AnyProperty } from '@/utilities.types';
import { logError } from '@/utilities/logger';

/**
 * Gets a custom property from an item using said propertys column key.
 *
 * @param item - The item object containing data displayed in the column
 * @param columnKey - key to match to object property, column.key or column.uomKey
 *
 * @return The property of `item` that the key matched to
 */
const getUsingKey = (item: AnyProperty, columnKey: string | undefined): string | number => {
  if (!columnKey) {
    return;
  }

  const [parentPropName, ...rest] = _.split(columnKey, '.');

  return _.get(item, _.chain([parentPropName]).concat(rest.join('.')).compact().value());
};

/**
 * Get the value and UOM of a column for an item
 *
 * @param {Object} column - One of the columns from COLUMNS
 * @param {Object} item - Item for which to get a value
 *
 * @returns {String} Formatted value
 */
export function getColumnValueAndUOM(column, item) {
  const isStartTime = column.key === 'startTime';
  const isEndTime = column.key === 'endTime';
  const isUncertain = item?.isUncertain;

  if (isStartTime && _.isNil(item.startTime)) {
    return i18next.t('CAPSULES_PANEL.STARTS_OFF_SCREEN');
  }

  if (isEndTime && _.isNil(item.endTime)) {
    return i18next.t('CAPSULES_PANEL.ENDS_OFF_SCREEN');
  }

  const formatOptions = item.formatOptions ?? column.series?.formatOptions ?? {};

  const rawValue = _.get(item, column.accessor) ?? getUsingKey(item, column.key);
  const value =
    column.style === 'string' || isProperty(column) || (column.isStringCompatible && _.isString(rawValue))
      ? parseEnum(rawValue) || rawValue
      : formatNumber(
          getUsingKey(item, column.key),
          {
            ...formatOptions,
            format: column.format || formatOptions.format,
            isPercent: column.style === 'percent',
          },
          logError,
        );

  const uom = getUsingKey(item, column.uomKey);

  return `${_.isNil(value) ? '' : value}${_.isNil(uom) || uom === STRING_UOM ? '' : uom}`;
}

/**
 * Determines if the column is a statistic (e.g. Max of a series)
 *
 * @param {Object} column - One of the columns from COLUMNS
 *
 * @return {boolean} True if it is a custom statistic column
 */
export function isStatistic(column) {
  return _.includes(column.key, 'statistics');
}

/**
 * Determines if the column is a custom property on the item (e.g. Batch ID)
 *
 * @param {Object} column - One of the columns from COLUMNS
 *
 * @return {boolean} True if it is a custom statistic column
 */
export function isProperty(column) {
  return _.includes(column.key, 'properties');
}

export function isValueColumn(column) {
  return (
    column.style !== 'icon' &&
    column.style !== 'select' &&
    column.style !== 'input' &&
    column.style !== 'iconSelect' &&
    column.style !== 'assets' &&
    column.style !== 'datasourceName' &&
    column.key !== 'valueUnitOfMeasure'
  );
}

export function parseEnum(value) {
  const match = value?.toString().match(ENUM_REGEX);
  return match ? match[2] : undefined;
}
