import { ValueOf } from '@/utilities.types';
import enJson from '../../public/i18n/en.json';
import esLAJson from '../../public/i18n/es-LA.json';
import frFRJson from '../../public/i18n/fr-FR.json';
import deDEJson from '../../public/i18n/de-DE.json';
import jaJPJson from '../../public/i18n/ja-JP.json';
import koKOJson from '../../public/i18n/ko-KO.json';
import ptBRJson from '../../public/i18n/pt-BR.json';

export const LOCALES = {
  EN: 'en',
  ES: 'es-LA',
  DE: 'de-DE',
  FR: 'fr-FR',
  JA: 'ja-JP',
  KO: 'ko-KO',
  PT: 'pt-BR',
} as const;
export type LocaleKey = ValueOf<typeof LOCALES>;

/**
 * For the language selector in the user profile modal, we do not want to translate the text since we want each
 * language label to be in its respective language.
 */
type LocaleData = { key: LocaleKey; text: string; translationPath: any };
export const LOCALE_DATA: LocaleData[] = [
  { key: LOCALES.EN, text: 'English', translationPath: enJson },
  { key: LOCALES.ES, text: 'Español', translationPath: esLAJson },
  { key: LOCALES.FR, text: 'Français', translationPath: frFRJson },
  { key: LOCALES.DE, text: 'Deutsch', translationPath: deDEJson },
  { key: LOCALES.JA, text: '日本語', translationPath: jaJPJson },
  { key: LOCALES.KO, text: '한국어', translationPath: koKOJson },
  { key: LOCALES.PT, text: 'Português', translationPath: ptBRJson },
];
