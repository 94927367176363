// @ts-strict-ignore
import _ from 'lodash';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { flux } from '@/core/flux.module';
import Highcharts from 'other_components/highcharts';
import { LOCALE_DATA, LocaleKey, LOCALES } from '@/utilities/i18n.constants';

export function init18n() {
  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      fallbackLng: 'en',
      compatibilityJSON: 'v3',
      supportedLngs: _.values(LOCALES),
      nonExplicitSupportedLngs: false,
      interpolation: {
        skipOnVariables: true,
        escapeValue: false,
      },
      backend: {
        backends: [HttpApi],
        backendOptions: [
          {
            loadPath: ([language]) => _.find(LOCALE_DATA, ({ key }) => key === language)!.translationPath,
            allowMultiLoading: false,
          },
        ],
      },
      react: {
        useSuspense: true,
      },
    });
}

/**
 * Return only the language portion of the locale string. For example, a locale of "pt-BR" would return "pt".
 *
 * @param locale - The locale
 */
export function getLanguage(locale: LocaleKey) {
  return locale.slice(0, 2);
}

export function getBrowserLanguage() {
  return navigator.language || (navigator as any).userLanguage;
}

/**
 * Switches to the provided language. This function also sets the moment locale and loads the appropriate
 * Highcharts translations file, and makes sure that the Tools are properly translated.
 *
 * @param locale - The locale key
 */
export async function switchLanguage(locale: LocaleKey) {
  await i18next.changeLanguage(locale);
  Highcharts.setOptions({ lang: i18next.t('HIGHCHARTS', { returnObjects: true }) });
  // NOTE: we do not want to set a new locale for moment as users are used to having it use their browser
  // locale (CRAB-24568)
  flux.dispatch('INVESTIGATE_TRIGGER_INVESTIGATE_TOOL_TRANSLATION');
  flux.dispatch('SET_USER_LANGUAGE', locale);
}
