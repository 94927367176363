// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDatasources } from '@/administration/datasources/datasources.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import i18next from 'i18next';
import {
  AgentStatusOutputV1,
  ConfigurationOptionOutputV1,
  ConnectionOutputV1,
  ConnectionStatusOutputV1,
  ConnectorOutputV1,
  DatasourcesStatusOutputV1,
  DatasourceSummaryStatusOutputV1,
  ScalarPropertyV1,
  sqAgentsApi,
  sqDatasourcesApi,
  sqFormulasApi,
  sqItemsApi,
  sqRequestsApi,
  sqSystemApi,
} from '@/sdk';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { logError } from '@/utilities/logger';
import { errorToast, infoToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { subscribe as subscribeToSocket } from '@/utilities/socket.utilities';
import { SyncStatusEnum } from '@/sdk/model/ConnectionStatusOutputV1';
import { parseDuration } from '@/datetime/dateTime.utilities';

let unsubscribeFn: () => void = _.noop;

export function subscribe() {
  unsubscribeFn();
  unsubscribeFn = subscribeToSocket({
    channelId: [SeeqNames.Channels.DatasourcesStatus],
    onMessage: ({ datasourcesStatus }) => {
      setDatasources(datasourcesStatus);
    },
  });
}

export function unsubscribe() {
  unsubscribeFn();
  unsubscribeFn = _.noop;
}

/**
 * Fetch datasources immediately and log an error if the datasources could not be fetched.
 */
export function fetchDatasourcesImmediately() {
  return sqAgentsApi
    .getDatasourcesStatus()
    .then(({ data: datasourcesStatus }) => datasourcesStatus)
    .catch((ex) => {
      logError(ex);
      return Promise.reject();
    });
}

/**
 * Returns the number of DISCONNECTED agents
 */
export function countDisconnectedAgents(agents: AgentStatusOutputV1[]) {
  if (_.isNil(agents)) {
    return 0;
  }
  return _.countBy(agents, (agent) => agent.status === SeeqNames.Connectors.Connections.Status.Disconnected).true || 0;
}

/**
 * Filters the list of datasources by the filter parameters provided and returns them in the severity order
 * (disconnected first, happy last)
 */
export function filterAndSortDatasources(
  datasources: DatasourceSummaryStatusOutputV1[],
  filterParams: FilterParameters,
) {
  if (_.isNil(datasources)) {
    return null;
  }

  const containsIgnoreCase = (str1, str2) => _.includes(_.toString(str1).toLowerCase(), _.toString(str2).toLowerCase());

  const hasValue = (fieldValue) => {
    return !_.isEmpty(fieldValue);
  };

  let filteredDatasources = datasources;

  if (hasValue(filterParams.name)) {
    filteredDatasources = _.filter(filteredDatasources, (ds) => containsIgnoreCase(ds.name, filterParams.name));
  }

  if (hasValue(filterParams.datasourceClass)) {
    filteredDatasources = _.filter(filteredDatasources, (ds) =>
      containsIgnoreCase(ds.datasourceClass, filterParams.datasourceClass),
    );
  }

  if (hasValue(filterParams.datasourceId)) {
    filteredDatasources = _.filter(filteredDatasources, (ds) =>
      containsIgnoreCase(ds.datasourceId, filterParams.datasourceId),
    );
  }

  if (hasValue(filterParams.agentName)) {
    filteredDatasources = _.filter(
      filteredDatasources,
      (ds) => _.countBy(ds.connections, (conn) => containsIgnoreCase(conn.agentName, filterParams.agentName)).true > 0,
    );
  }

  if (hasValue(filterParams.status)) {
    filteredDatasources = _.filter(
      filteredDatasources,
      (ds) => _.countBy(ds.connections, (conn) => conn.status === filterParams.status).true > 0,
    );
  }

  // sorting
  const newDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isPlaceholder(ds) && isNew(ds)),
    'name',
  );
  const invalidDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isPlaceholder(ds) && !isNew(ds)),
    'name',
  );
  filteredDatasources = _.filter(filteredDatasources, (ds) => !isPlaceholder(ds));
  const errorDatasources = _.sortBy(_.filter(filteredDatasources, isError), 'name');
  filteredDatasources = _.difference(filteredDatasources, errorDatasources);
  const indexingDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isIndexing(ds)),
    'name',
  );
  filteredDatasources = _.difference(filteredDatasources, indexingDatasources);
  const warningDatasources = _.sortBy(_.filter(filteredDatasources, isWarning), 'name');
  filteredDatasources = _.difference(filteredDatasources, warningDatasources);
  const happyDatasources = _.sortBy(_.filter(filteredDatasources, isHappy), 'name');
  filteredDatasources = _.difference(filteredDatasources, happyDatasources);
  const notConnectable = _.sortBy(_.filter(filteredDatasources, isNotConnectable), 'name');
  filteredDatasources = _.sortBy(_.difference(filteredDatasources, notConnectable), 'name');

  return _.concat(
    newDatasources,
    invalidDatasources,
    errorDatasources,
    indexingDatasources,
    warningDatasources,
    happyDatasources,
    notConnectable,
    filteredDatasources,
  );
}

export function getDatasourceStatus(datasource: DatasourceSummaryStatusOutputV1) {
  if (isPlaceholder(datasource) && isNew(datasource)) {
    return DatasourceStatus.New;
  } else if (isError(datasource)) {
    return DatasourceStatus.Error;
  } else if (isIndexing(datasource)) {
    return DatasourceStatus.Indexing;
  } else if (isWarning(datasource)) {
    return DatasourceStatus.Warning;
  } else if (isHappy(datasource)) {
    return DatasourceStatus.Happy;
  } else if (isNotConnectable(datasource)) {
    return DatasourceStatus.NotConnectable;
  } else {
    return DatasourceStatus.Unknown;
  }
}

export function isPlaceholder(ds: DatasourceSummaryStatusOutputV1) {
  return ds.placeholder === true;
}

export function datasourceDisplayName(ds: DatasourceSummaryStatusOutputV1) {
  const dsClassName = isNew(ds)
    ? ds.datasourceClass.replace(/Invalid (.*) Connection/, 'Pending $1 Connection')
    : ds.datasourceClass;
  const displayDsClass = isPlaceholder(ds) ? `[${dsClassName}]` : dsClassName;
  return `${displayDsClass}: ${ds.name}`;
}

const isError = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount === 0 && ds.totalConnectionsCount > 0;
};

export function isIndexing(ds: DatasourceSummaryStatusOutputV1) {
  return _.includes(
    [SyncStatusEnum.ARCHIVINGDELETEDITEMS, SyncStatusEnum.INPROGRESS, SyncStatusEnum.INITIALIZING],
    ds.syncStatus,
  );
}

export function latestOf(moments: moment.Moment[]): moment.Moment {
  let latest = _.isEmpty(moments) ? moment(0) : moments[0];
  moments.forEach((thisMoment) => {
    latest = latest.isBefore(thisMoment) ? thisMoment : latest;
  });
  return latest;
}

export function isIndexingProgressing(referenceTime: moment.Moment, indexingNoProgressLimit: moment.Duration): boolean {
  return moment().isBefore((referenceTime ?? moment(0)).clone().add(indexingNoProgressLimit));
}

const isWarning = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount > 0 && ds.connectionsConnectedCount < ds.totalConnectionsCount;
};

const isHappy = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount === ds.totalConnectionsCount && ds.totalConnectionsCount > 0;
};

const isNotConnectable = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount === 0 && ds.totalConnectionsCount === 0;
};

function isNew(datasource: DatasourceSummaryStatusOutputV1) {
  const isNewLimit = moment.duration(30, 'seconds');
  return _.isEmpty(datasource.connections) || _.isNil(datasource.connections[0].createdAt)
    ? false
    : moment().subtract(isNewLimit).isBefore(moment(datasource.connections[0].createdAt));
}

/**
 * Cancels all requests to the selected datasource
 */
export function cancelAllRequests(datasource: DatasourceSummaryStatusOutputV1): Promise<void> {
  const datasourceClass = datasource.datasourceClass;
  const datasourceId = datasource.datasourceId;
  return sqRequestsApi
    .cancelRequests({ datasourceClass, datasourceId })
    .then(() => {
      successToast({
        messageKey: 'ADMIN.DATASOURCE.CANCELED_ALL_SUCCESS',
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Request indexing of the first non DISABLED connection or reports the error given as parameter.
 */
export function requestIndex(datasource: DatasourceSummaryStatusOutputV1) {
  return sqAgentsApi
    .index(
      {
        syncMode: 'FULL',
      },
      {
        datasourceClass: datasource.datasourceClass,
        datasourceId: datasource.datasourceId,
      },
    )
    .then(() => {
      successToast({
        messageKey: 'ADMIN.DATASOURCES.REQUESTED_INDEX_SUCCESS',
        messageParams: {
          datasourceClass: datasource.datasourceClass,
          datasourceId: datasource.datasourceId,
        },
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function setDatasourceAllowRequests(
  datasource: DatasourceSummaryStatusOutputV1,
  allowRequests: boolean,
): Promise<void> {
  return sqItemsApi
    .setProperty({ value: allowRequests }, { id: datasource.id, propertyName: SeeqNames.Properties.Enabled })
    .then(() => {
      const newStatus = allowRequests
        ? 'ADMIN.DATASOURCES.ALLOWS_REQUESTS'
        : 'ADMIN.DATASOURCES.DOES_NOT_ALLOW_REQUESTS';
      const messageParams = {
        datasourceName: datasource.name,
        datasourceId: datasource.datasourceId,
      };
      successToast({ messageKey: newStatus, messageParams });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function setCacheEnabled(datasource: DatasourceSummaryStatusOutputV1, cacheEnabled: boolean): Promise<void> {
  const messageParams = {
    datasourceName: datasource.name,
  };
  if (cacheEnabled) {
    infoToast({
      messageKey: 'ADMIN.DATASOURCES.CACHE_ENABLED_INFO_MESSAGE',
      messageParams,
    });
  } else {
    infoToast({
      messageKey: 'ADMIN.DATASOURCES.CACHE_DISABLED_INFO_MESSAGE',
      messageParams,
    });
  }
  return sqItemsApi
    .setProperty({ value: cacheEnabled }, { id: datasource.id, propertyName: SeeqNames.Properties.CacheEnabled })
    .then(() => {})
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

/**
 * This method exists to provide a mechanism to return pages of results sequentially rather than all at once. The
 * motivating use case was to pass a very large list of filters to the GET /items request, since it is possible for
 * the URL to be too long to be handled correctly:
 * {@link https://stackoverflow.com/questions/812925/what-is-the-maximum-possible-length-of-a-query-string}
 * Some customers have hundreds of datasources, so to compute the datasource metrics could require thousands
 * of signals, each of which requires a very long filter to look up.  The sequential pagination may make the lookup
 * take a bit longer but avoids slamming the API with dozens of concurrent requests.
 *
 * @template InputType
 * @template OutputType
 * @param {InputType[]} input - An array on which {@link fn} acts
 * @param {number} pageSize - The size to use for pages of {@link input}
 * @param {(arr: InputType[]) => Promise<OutputType[]>} fn - A function that acts on a page of the {@link input}
 * array to produce a promise that resolves to a page of {@link OutputType} objects
 * @returns {Promise<OutputType[]>} - A promise that resolves to an array of {@link OutputType} objects
 */
export function pagePromises<InputType, OutputType>(
  input: InputType[],
  pageSize: number,
  fn: (arr: InputType[]) => Promise<OutputType[]>,
): Promise<OutputType[]> {
  function pagedPromises<InputType, OutputType>(
    input: InputType[],
    output: OutputType[],
    pageSize: number,
    fn: (arr: InputType[]) => Promise<OutputType[]>,
  ): Promise<OutputType[]> {
    return pageSize > input.length
      ? fn(input).then((result) => _.concat(output, result))
      : fn(_.slice(input, 0, pageSize))
          .then((result) => {
            return Promise.all([result, pagedPromises(_.slice(input, pageSize), output, pageSize, fn)]);
          })
          .then(([page, morePages]) => _.concat(page, morePages));
  }

  return pagedPromises(input, [] as OutputType[], pageSize, fn);
}

export function getMonitorsDatasourceConfig() {
  return sqSystemApi
    .getConfigurationOptions({ offset: 0, limit: 10000 })
    .then(({ data }) =>
      _.filter(data.configurationOptions, (option) => _.startsWith(option.path, 'Features/Monitors/Datasource')),
    );
}

function getOrDefault(configParam: ConfigurationOptionOutputV1) {
  return configParam?.value ?? configParam?.defaultValue;
}

function getMonitorsDatasourceId(monitorsDatasourceConfig: ConfigurationOptionOutputV1[]) {
  const machineName = getOrDefault(
    _.find(monitorsDatasourceConfig, (option) => option.path === 'Features/Monitors/Datasource/MachineName'),
  );
  return `monitors-${machineName}`;
}

function isMonitorsDatasourceEnabled(monitorsDatasourceConfig: ConfigurationOptionOutputV1[]) {
  return getOrDefault(
    _.find(monitorsDatasourceConfig, (option) => option.path === 'Features/Monitors/Datasource/Enabled'),
  );
}

function isMonitorsImportFrequencyOk(monitorsDatasourceConfig: ConfigurationOptionOutputV1[]) {
  const importFrequency = getOrDefault(
    _.find(monitorsDatasourceConfig, (option) => option.path === 'Features/Monitors/Datasource/ImportFrequency'),
  );
  return _.isNumber(importFrequency) && moment.duration(importFrequency, 'seconds') <= moment.duration(15, 'minutes');
}

export function confirmMonitorsDatasourceExists(configurationOptions: ConfigurationOptionOutputV1[]) {
  return sqDatasourcesApi
    .getDatasources({
      datasourceClass: SeeqNames.LocalDatasources.Monitors.DatasourceClass,
      datasourceId: getMonitorsDatasourceId(configurationOptions),
    })
    .then(({ data: { datasources } }) => datasources.length === 1);
}

export function canComputeMetrics(
  monitorsDatasourceAvailable: boolean,
  configurationOptions: ConfigurationOptionOutputV1[],
) {
  return (
    monitorsDatasourceAvailable &&
    isMonitorsDatasourceEnabled(configurationOptions) &&
    isMonitorsImportFrequencyOk(configurationOptions)
  );
}

export function getMetricsTooltip(
  monitorsDatasourceAvailable: boolean,
  monitorsDatasourceConfig: ConfigurationOptionOutputV1[],
): string {
  if (!isMonitorsDatasourceEnabled(monitorsDatasourceConfig)) {
    return 'ADMIN.DATASOURCES.FILTER.MONITORS_DATASOURCE_DISABLED';
  } else if (!monitorsDatasourceAvailable) {
    return 'ADMIN.DATASOURCES.FILTER.MONITORS_DATASOURCE_NOT_FOUND';
  } else if (!isMonitorsImportFrequencyOk(monitorsDatasourceConfig)) {
    return 'ADMIN.DATASOURCES.FILTER.MONITORS_IMPORT_FREQUENCY_TOO_LOW';
  }
  return 'ADMIN.DATASOURCES.FILTER.METRICS_TIME_RANGE_TOOLTIP';
}

export function getUpdatedMetrics(
  timeRange: string,
  filteredDatasources,
  monitorsDatasourceConfig: ConfigurationOptionOutputV1[],
): Promise<DatasourcesMetrics> {
  const monitorsDatasourceId = getMonitorsDatasourceId(monitorsDatasourceConfig);
  const parsedTimeRange = parseDuration(timeRange);
  if (parsedTimeRange.valueOf() === moment.duration(0).valueOf()) {
    if (_.trim(timeRange) !== '') {
      warnToast({ messageKey: 'ADMIN.DATASOURCES.TIME_RANGE_INVALID_FORMAT' });
    }
    return Promise.resolve({ timeRange, metrics: new Map() });
  }
  if (parsedTimeRange < moment.duration(1, 'hour') || parsedTimeRange > moment.duration(90, 'days')) {
    warnToast({ messageKey: 'ADMIN.DATASOURCES.TIME_RANGE_INVALID_LENGTH' });
    return Promise.resolve({ timeRange, metrics: new Map() });
  }
  return _.isEmpty(filteredDatasources)
    ? Promise.resolve({ timeRange, metrics: new Map() })
    : updateMetricsInternal(filteredDatasources, parsedTimeRange, monitorsDatasourceId).then((datasourceMetrics) => {
        return {
          timeRange,
          metrics: new Map(datasourceMetrics.map((metrics) => [metrics.DatasourceSeeqId, metrics])),
        };
      });
}

function getDataId(datasource: DatasourceSummaryStatusOutputV1, relativePath: string): string {
  const dsClass = datasource.datasourceClass.replaceAll('.', '_');
  const dsId = datasource.datasourceId.replaceAll('.', '_');
  const dsName = datasource.name.replaceAll('.', '_');
  return `Datasource/${dsClass}/${dsName} - ${dsId}/${relativePath}`;
}

export function updateMetricsInternal(
  datasources: DatasourceSummaryStatusOutputV1[],
  duration: moment.Duration,
  monitorsDsId: string,
): Promise<DatasourceMetrics[]> {
  // This string will be used in a Seeq formula, so no translation is needed
  const durationSeconds = `${duration.asSeconds()} seconds`;
  const monitorsDsClass = SeeqNames.LocalDatasources.Monitors.DatasourceClass;

  const parameterFilters = _.flatMap(datasources, (datasource) => {
    const getSearchFilter = (dataId) => {
      return `Datasource Class==${monitorsDsClass} && Datasource ID==${monitorsDsId} && Data ID==${dataId}`;
    };
    return [
      getSearchFilter(getDataId(datasource, 'Rx/Successes.Meter')),
      getSearchFilter(getDataId(datasource, 'Rx/Failures.Meter')),
      getSearchFilter(getDataId(datasource, 'Rx/Samples.Meter')),
    ];
  });

  const parameterSearch = (filters: string[]): Promise<{ searchFilter: string; seeqId: string }[]> =>
    sqItemsApi.searchItems({ filters }).then(({ data }) =>
      _.map(filters, (filter) => ({
        searchFilter: filter,
        seeqId: _.find(
          data.items,
          (item) =>
            _.endsWith(filter, item.name) &&
            _.includes(filter, `Data ID==${_.tail(_.map(item.ancestors, (item) => item.name)).join('/')}`),
        )?.id,
      })),
    );

  return pagePromises(parameterFilters, 40, parameterSearch).then((results) =>
    runMetricTableFormulas(results, datasources, durationSeconds),
  );
}

function runMetricTableFormulas(
  inputs: { searchFilter: string; seeqId: string }[],
  datasources: DatasourceSummaryStatusOutputV1[],
  durationSeconds: string,
): Promise<DatasourceMetrics[]> {
  const countingTableFormula = (
    datasources: DatasourceSummaryStatusOutputV1[],
    signalIdProvider: (datasource: DatasourceSummaryStatusOutputV1) => string,
    durationSeconds: string,
  ): string => {
    const countFormula = (datasource: DatasourceSummaryStatusOutputV1, index) =>
      _.isUndefined(signalIdProvider(datasource))
        ? '0'
        : `$signal${index}.runningDelta().max(0).sum(capsule(now().subtract(${durationSeconds}), now()))`;
    const addColumnText = _.map(
      datasources,
      (datasource, index) => `.addColumn('${datasource.id}', $id -> ${countFormula(datasource, index)})`,
    ).join('');
    return `toTable('Metric', 'Count')${addColumnText}`;
  };

  const countingTableParameters = (
    datasources: DatasourceSummaryStatusOutputV1[],
    signalIdProvider: (datasource: DatasourceSummaryStatusOutputV1) => string,
  ): string[] => {
    const countFormulaParameters = (datasource: DatasourceSummaryStatusOutputV1, index) =>
      _.isUndefined(signalIdProvider(datasource)) ? [] : [`signal${index}=${signalIdProvider(datasource)}`];
    return _.flatMap(datasources, (datasource: DatasourceSummaryStatusOutputV1, index) =>
      countFormulaParameters(datasource, index),
    );
  };

  const successCountSignalProvider = (datasource: DatasourceSummaryStatusOutputV1): string => {
    return _.find(inputs, (r) => _.endsWith(r.searchFilter, getDataId(datasource, 'Rx/Successes.Meter')))?.seeqId;
  };
  const successCountTableFormula = sqFormulasApi.runFormula({
    formula: countingTableFormula(datasources, successCountSignalProvider, durationSeconds),
    parameters: countingTableParameters(datasources, successCountSignalProvider),
  });

  const failureCountSignalProvider = (datasource: DatasourceSummaryStatusOutputV1): string => {
    return _.find(inputs, (r) => _.endsWith(r.searchFilter, getDataId(datasource, 'Rx/Failures.Meter')))?.seeqId;
  };
  const failureCountTableFormula = sqFormulasApi.runFormula({
    formula: countingTableFormula(datasources, failureCountSignalProvider, durationSeconds),
    parameters: countingTableParameters(datasources, failureCountSignalProvider),
  });

  const sampleCountSignalProvider = (datasource: DatasourceSummaryStatusOutputV1): string => {
    return _.find(inputs, (r) => _.endsWith(r.searchFilter, getDataId(datasource, 'Rx/Samples.Meter')))?.seeqId;
  };
  const sampleCountTableFormula = sqFormulasApi.runFormula({
    formula: countingTableFormula(datasources, sampleCountSignalProvider, durationSeconds),
    parameters: countingTableParameters(datasources, sampleCountSignalProvider),
  });

  return Promise.all([successCountTableFormula, failureCountTableFormula, sampleCountTableFormula])
    .then(([successCountTableData, failureCountTableData, sampleCountTableData]) => {
      const dataFromTable = (tableResponse) =>
        Object.fromEntries(
          _.map(_.tail(tableResponse.data.table.headers), (datasourceSeeqId: { name: string; type: string }, index) => [
            datasourceSeeqId.name,
            _.tail(tableResponse.data.table.data[0])[index],
          ]),
        );
      return _.map(datasources, (datasource) => {
        return {
          DatasourceSeeqId: datasource.id,
          SuccessCount: dataFromTable(successCountTableData)[datasource.id],
          FailureCount: dataFromTable(failureCountTableData)[datasource.id],
          SampleCount: dataFromTable(sampleCountTableData)[datasource.id],
        } as DatasourceMetrics;
      });
    })
    .catch(() => {
      errorToast({ messageKey: 'ADMIN.DATASOURCES.METRICS.CALCULATION_ERROR' });
      return undefined;
    });
}

export function setConnectionEnabled(connection: ConnectionStatusOutputV1, connectionEnabled: boolean): Promise<void> {
  const connectorName = connection.connectorName;
  const connectionKey = {
    agentName: connection.agentName,
    connectionName: connection.name,
    connectorName,
  };

  return sqAgentsApi
    .getConnection(connectionKey)
    .then(({ data }) =>
      sqAgentsApi.createOrUpdateConnection(
        {
          datasourceId: data.datasourceId,
          enabled: connectionEnabled,
          json: data.json,
          maxConcurrentRequests: data.maxConcurrentRequests,
          maxResultsPerRequests: data.maxResultsPerRequests,
          transforms: data.transforms,
        },
        connectionKey,
      ),
    )
    .then(({ data }) => {
      successToast({
        messageKey: data.enabled
          ? 'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_ENABLED'
          : 'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_DISABLED',
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function archiveDatasource(id: string): Promise<void> {
  // The fullyArchiveDatasource endpoint returns a 202 Accepted status code and conducts the archival async, but we
  // do not currently have a follow-up promise for the datasource archival job (see CRAB-35122). Thus, useManualAsync is
  // set to true so that the success toast is shown and the frontend is not waiting on a follow-up promise.
  return sqDatasourcesApi
    .fullyArchiveDatasource({ id }, { useManualAsync: true })
    .then(() => {
      successToast({
        messageKey: 'ADMIN.DATASOURCES.DATASOURCE_ARCHIVAL_HAS_STARTED',
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

export function getConnection(connection) {
  const { agentName, connectorName, name: connectionName } = connection;
  return sqAgentsApi
    .getConnection({ agentName, connectorName, connectionName })
    .then(({ data }) => data)
    .then((data) => {
      const { backups, effectivePermissions, createdAt, updatedAt, datasourceId, ...connection } = data;
      if (_.isNil(connection.transforms)) {
        connection.transforms = undefined;
      } else {
        connection.transforms = JSON.parse(connection.transforms);
      }
      connection.json = JSON.parse(connection.json);
      return connection;
    });
}

export function getConnectionNames(agentName: string, connectorName: string) {
  return sqAgentsApi
    .getConnector({ agentName, connectorName })
    .then(({ data }) => data)
    .then((data: ConnectorOutputV1) => {
      let jsonObj;
      try {
        jsonObj = JSON.parse(data.json);
      } catch (e) {
        return [];
      }

      if (jsonObj.DatasourceManaged) {
        return Promise.reject({
          data: {
            statusMessage: i18next.t('ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTOR_IS_DATASOURCE_MANAGED'),
          },
        });
      }

      if (!_.isNil(jsonObj.Connections)) {
        return _.map(jsonObj.Connections, (connection) => {
          return { value: connection.Name, label: connection.Name };
        });
      }
      return [];
    })
    .catch(({ data }) => {
      return Promise.reject(_.get(data, 'statusMessage'));
    });
}

export function getConnectorNames(
  agentName: string,
  filter?: (c: { Name: string; Enabled: boolean }) => boolean,
): Promise<{ label: string; value: string }[]> {
  return sqAgentsApi
    .getAgent({ agentName })
    .then(({ data }) => data)
    .then((data: ConnectionOutputV1) => {
      let jsonObj;
      try {
        jsonObj = JSON.parse(data.json);
      } catch (e) {
        return [];
      }
      if (!_.isNil(jsonObj.Connectors)) {
        return _.map(_.filter(jsonObj.Connectors, filter), (connector) => {
          return { value: connector.Name, label: connector.Name };
        });
      }
      return [];
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      return [];
    });
}

export function createOrUpdateConnection(
  isNew: boolean,
  agentName: string,
  datasourceId: string,
  connection: ConnectionOutputV1,
): Promise<void> {
  let transforms = null;
  if (_.isArray(connection.transforms)) {
    if (_.keys(connection.transforms).length > 0) {
      transforms = JSON.stringify(connection.transforms);
    } else {
      transforms = '[]';
    }
  }
  const body = {
    maxConcurrentRequests: connection.maxConcurrentRequests,
    maxResultsPerRequests: connection.maxResultsPerRequests,
    transforms,
    enabled: connection.enabled,
    json: JSON.stringify(connection.json),
  };
  if (isNew) {
    body['datasourceId'] = datasourceId;
  }
  const connectorName = connection.connectorName;
  const connectionName = connection.name;

  return sqAgentsApi
    .createOrUpdateConnection(body, {
      agentName,
      connectorName,
      connectionName,
    })
    .then(() => {
      if (isNew) {
        successToast({
          messageKey: 'ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTION_CREATED',
        });
      }
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
      return Promise.reject();
    });
}

/**
 * Sorts the provided list of connections in the correct order the frontend needs:
 * - First disconnected
 * - Then connecting
 * - Then indexing
 * - Then connected
 * - Then disabled
 */
export function sortConnections(connections: ConnectionStatusOutputV1[]): ConnectionStatusOutputV1[] {
  if (_.isNil(connections)) {
    return null;
  }

  let unprocessed = connections;

  const disconnected = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Disconnected),
    'name',
  );
  unprocessed = _.difference(unprocessed, disconnected);

  const connecting = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Connecting),
    'name',
  );
  unprocessed = _.difference(unprocessed, connecting);

  const indexing = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Indexing),
    'name',
  );
  unprocessed = _.difference(unprocessed, indexing);

  const connected = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Connected),
    'name',
  );
  unprocessed = _.difference(unprocessed, connected);

  const disabled = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Disabled),
    'name',
  );
  unprocessed = _.sortBy(_.difference(unprocessed, disabled), 'name');

  return _.concat(disconnected, connecting, indexing, connected, disabled, unprocessed);
}

export async function removeConnection(connection: ConnectionStatusOutputV1) {
  try {
    await sqAgentsApi.archiveConnection({
      agentName: connection.agentName,
      connectorName: connection.connectorName,
      connectionName: connection.name,
    });
    successToast({
      messageKey: 'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_REMOVED',
    });
  } catch (error) {
    errorToast({ httpResponseOrError: error });
  }
}

export function isConnectionRemovable(
  connection: ConnectionStatusOutputV1,
  datasource: DatasourceSummaryStatusOutputV1,
): boolean {
  return (
    isPlaceholder(datasource) ||
    (datasource?.connections?.length > 1 &&
      connection.status !== SeeqNames.Connectors.Connections.Status.Connected &&
      connection.status !== SeeqNames.Connectors.Connections.Status.Connecting)
  );
}

export function getConnectionStatus(connection: ConnectionStatusOutputV1) {
  if (_.isNil(connection)) {
    return ConnectionStatus.Unknown;
  }

  if (connection.status === SeeqNames.Connectors.Connections.Status.Disconnected) {
    return ConnectionStatus.Disconnected;
  } else if (connection.status === SeeqNames.Connectors.Connections.Status.Disabled) {
    return ConnectionStatus.Disabled;
  } else if (connection.status === SeeqNames.Connectors.Connections.Status.Connecting) {
    return ConnectionStatus.Connecting;
  } else if (connection.status === SeeqNames.Connectors.Connections.Status.Connected) {
    if (connection.syncStatus === SyncStatusEnum.INPROGRESS) {
      return ConnectionStatus.Indexing;
    } else {
      return ConnectionStatus.Connected;
    }
  } else {
    return ConnectionStatus.Unknown;
  }
}

/**
 * Computes the url where the logs of the agent can be retrieved.
 */
export function computeLogUrl(
  agentName: string,
  agents: AgentStatusOutputV1[],
  connection?: ConnectionStatusOutputV1,
): string {
  const agent = _.find(agents, (a) => a.name === agentName);

  let logName;
  if (_.isNil(agent)) {
    logName = null;
  } else if (agent.remoteAgent) {
    // Keep this in sync with RemoteAgentLoggingService
    logName = agent.name
      .replace(/[\\/:*?"<>|]/g, '')
      .replace(/[.\s]/g, '_')
      .replace(/(\d)$/g, '$1_');
  } else if (_.includes(agent.name, 'JVM Agent')) {
    logName = 'jvm-link';
  } else if (_.includes(agent.name, '.NET Agent')) {
    logName = 'net-link';
  } else {
    logName = null;
  }

  let url = '/logs';

  if (!_.isNil(logName)) {
    url += `?log=${encodeURIComponent(logName)}`;
  }

  if (!_.isNil(connection) && !_.isNil(connection.lastSuccessfulConnectedAt)) {
    if (_.isNil(logName)) {
      url += '?';
    } else {
      url += '&';
    }

    url += `threadContains=${encodeURIComponent(connection.connectionId)}`;
  }

  return url;
}

/**
 * Fetch parameters required by the ManageDatasourceModal in the format it expects
 *
 * @param id - the datasource ID
 */
export function fetchManageDatasourceParams(id: string): Promise<ManageDatasourceParams | void> {
  return sqDatasourcesApi
    .getDatasource({ id })
    .then(({ data: { name, indexingScheduleSupported, additionalProperties } }) => {
      const indexingFrequency = _.chain(additionalProperties)
        .filter(['name', SeeqNames.Properties.IndexingFrequency])
        .map((prop) => ({ value: prop.value, units: prop.unitOfMeasure }))
        .first()
        .value();
      const nextScheduledIndexAt = _.chain(additionalProperties)
        .filter(['name', SeeqNames.Properties.NextScheduledIndexAt])
        .map((prop) => moment.utc(prop.value / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND).toISOString())
        .first()
        .value();

      return {
        id,
        name,
        indexingScheduleSupported,
        indexingFrequency,
        nextScheduledIndexAt,
      };
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Update the datasource
 *
 * @param id - the datasource ID
 * @param name - the new name of the datasource
 * @param indexingFrequency - the new indexing frequency
 * @param nextScheduledIndexAt - the new next scheduled indexing time, formatted as ISO 8601 in UTC
 * @param connections - the list of connections of the datasource
 * @param connectionsEnabled - a boolean representing the new global enabled state of the connections
 * @param connectionsEnabledWasUpdated - a boolean representing whether the connections' enabled status should be
 * updated
 * @param datasourceArchived - a boolean representing whether the datasource should be archived now
 */
export function updateDatasource({
  id,
  name,
  indexingFrequency,
  nextScheduledIndexAt,
  connections,
  connectionsEnabled,
  connectionsEnabledWasUpdated,
  datasourceArchived,
}: UpdateDatasourceParams) {
  if (datasourceArchived) {
    return archiveDatasource(id);
  }

  const properties: ScalarPropertyV1[] = [
    {
      name: SeeqNames.Properties.Name,
      value: name,
    },
  ];

  if (!_.isUndefined(nextScheduledIndexAt)) {
    properties.push({
      name: SeeqNames.Properties.NextScheduledIndexAt,
      value: moment.utc(nextScheduledIndexAt).valueOf() * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
      unitOfMeasure: 'ns',
    });
  }

  if (!_.isUndefined(indexingFrequency)) {
    properties.push({
      name: SeeqNames.Properties.IndexingFrequency,
      value: indexingFrequency.value,
      unitOfMeasure: indexingFrequency.units,
    });
  }

  if (connectionsEnabledWasUpdated) {
    // Handles the case where the datasource has multiple connections in the backend but the frontend only sees one
    if (!connectionsEnabled) {
      return sqDatasourcesApi.disableConnections({ id }).then(() => sqItemsApi.setProperties(properties, { id }));
    } else {
      return Promise.all([
        ..._.map(connections, (connection) => setConnectionEnabled(connection, connectionsEnabled)),
      ]).then(() => sqItemsApi.setProperties(properties, { id }));
    }
  } else {
    return sqItemsApi.setProperties(properties, { id });
  }
}

export function getTrackableMessage(datasourcesStatus: DatasourcesStatusOutputV1) {
  if (_.isNil(datasourcesStatus)) {
    return datasourcesStatus;
  }
  const agents = _.isNil(datasourcesStatus.agents)
    ? datasourcesStatus.agents
    : _.map(datasourcesStatus.agents, (agent) => _.pick(agent, ['name', 'status']));

  const datasources = _.isNil(datasourcesStatus.datasources)
    ? datasourcesStatus.datasources
    : _.map(datasourcesStatus.datasources, (datasource) => {
        const relevantFields = _.pick(datasource, ['id', 'name', 'datasourceClass', 'datasourceId', 'syncProgress']);
        relevantFields.syncProgress = _.pick(relevantFields.syncProgress, [
          'signalCount',
          'conditionCount',
          'scalarCount',
          'assetCount',
          'userGroupCount',
        ]);
        return relevantFields;
      });

  return { agents, datasources };
}

export function getDatasourceItemsCount(datasource: DatasourceSummaryStatusOutputV1) {
  const syncProgress = datasource.syncProgress;

  return _.isNil(syncProgress)
    ? 0
    : (syncProgress.signalCount || 0) +
        (syncProgress.conditionCount || 0) +
        (syncProgress.scalarCount || 0) +
        (syncProgress.assetCount || 0) +
        (syncProgress.userGroupCount || 0);
}

export function getPreviousDatasourceItemsCount(datasource: DatasourceSummaryStatusOutputV1) {
  const syncProgress = datasource.syncProgress;

  return _.isNil(syncProgress)
    ? 0
    : (syncProgress.previousSignalCount || 0) +
        (syncProgress.previousConditionCount || 0) +
        (syncProgress.previousScalarCount || 0) +
        (syncProgress.previousAssetCount || 0) +
        (syncProgress.previousUserGroupCount || 0);
}

/**
 * Converts a nanoseconds duration to seconds (rounded).
 *
 * @param nanoseconds - the duration to be converted
 * @return the duration expressed in seconds
 */
export function convertNanosecondsToSeconds(nanoseconds: number) {
  return Math.round(nanoseconds / 1000000000);
}

export interface UpdateDatasourceParams {
  id: string;
  name: string;
  indexingFrequency: { value: number; units: string };
  nextScheduledIndexAt: string; // ISO 8601 in UTC (e.g. '2021-07-10T00:24:00.000Z')
  connections?: ConnectionStatusOutputV1[];
  connectionsEnabled?: boolean;
  connectionsEnabledWasUpdated?: boolean;
  datasourceArchived?: boolean;
}

export interface ManageDatasourceParams extends UpdateDatasourceParams {
  indexingScheduleSupported: boolean;
  localDatasource?: boolean;
}

export interface FilterParameters {
  name: string;
  datasourceClass: string;
  datasourceId: string;
  agentName: string;
  status: string;
  metricsTimeRange: string;
}

export interface DatasourceMetrics {
  DatasourceSeeqId: string;
  SuccessCount: number;
  FailureCount: number;
  SampleCount: number;
}

export interface DatasourcesMetrics {
  timeRange: string;
  metrics: Map<string, DatasourceMetrics>;
}

export enum DatasourceStatus {
  Unknown = 'Unknown',
  New = 'New',
  Error = 'Error',
  Indexing = 'Indexing',
  Warning = 'Warning',
  Happy = 'Happy',
  NotConnectable = 'NotConnectable',
}

export enum ConnectionStatus {
  Unknown = 'Unknown',
  Disconnected = 'Disconnected',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Indexing = 'Indexing',
  Disabled = 'Disabled',
}

const DatasourceStatusIcons = new Map([
  [DatasourceStatus.Unknown, 'fa-exclamation-triangle sq-status-error'],
  [DatasourceStatus.New, 'fa-question-circle sq-status-info'],
  [DatasourceStatus.Error, 'fa-exclamation-triangle sq-status-error'],
  [DatasourceStatus.Indexing, 'fa-refresh fa-spin sq-status-progress'],
  [DatasourceStatus.Warning, 'fa-exclamation-circle sq-status-warning'],
  [DatasourceStatus.Happy, 'fa-check-circle sq-status-good'],
  [DatasourceStatus.NotConnectable, 'fa-minus-circle disabledLook'],
]);

const ConnectionStatusIcons = new Map([
  [ConnectionStatus.Unknown, 'fa-exclamation-triangle sq-status-error width-17'],
  [ConnectionStatus.Disconnected, 'fa-exclamation-triangle sq-status-error width-17'],
  [ConnectionStatus.Connecting, 'fa-circle-o-notch fa-spin sq-status-progress width-17'],
  [ConnectionStatus.Connected, 'fa-check-circle sq-status-good width-17'],
  [ConnectionStatus.Indexing, 'fa-refresh fa-spin sq-status-progress width-17'],
  [ConnectionStatus.Disabled, 'fa-minus-circle disabledLook width-17'],
]);

export { ConnectionStatusIcons, DatasourceStatusIcons };
